<template>
    <v-row align="center" no-gutters>
        <v-col cols="12" class="text-capitalize"> {{ category }} </v-col>
        <v-col cols="9">
            <transition name="pulse" mode="out-in">
                <v-sparkline
                    :value="average"
                    :color="color"
                    height="100"
                    stroke-linecap="round"
                    line-width="width"
                    smooth
                    :key="currentCount"
                />
            </transition>
        </v-col>
        <v-col class="d-flex flex-column align-center justify-end">
            <transition name="pulse" mode="out-in">
                <div class="text-h5 font-weight-bold" :key="currentCount">
                    {{ currentAverage }}
                </div>
            </transition>
            <div class="text-caption" :key="currentCount">
                {{ currentCount }} responses
            </div>
        </v-col>
    </v-row>
</template>

<script>
import _last from 'lodash/last'

export default {
    props: {
        category: { type: String },
        average: { type: Array },
        count: { type: Array },
    },
    data() {
        return {
            color: this.$vuetify.theme.themes.light.primary,
        }
    },
    computed: {
        currentCount() {
            return _last(this.count)
        },
        currentAverage() {
            return _last(this.average).toFixed(1)
        },
    },
}
</script>

<style>
.pulse-enter {
    transform: scale(1.5);
    color: orange;
    opacity: 1;
}
.pulse-enter-active {
    transition: all 0.1s;
}
.pulse-enter-to {
    transform: scale(1);
    opacity: 1;
}
.pulse-leave {
    opacity: 1;
}
.pulse-leave-active {
    transition: all 0.2s;
}
.pulse-leave-to {
    transform: scale(0.1);
    opacity: 0;
}
</style>
